import React, { useState } from "react";
import { checkLogin,getVariable } from "./Helpers/Session";

import Layout from "./pages/LoginRequired/Layout/Layout";
import Login from "./pages/LoginNotRequired/Auth/Login";
import CompanySelection from "./pages/LoginNotRequired/Auth/CompanySelection";

function App() {
  const [userProfile, setUserProfile] = useState(checkLogin());
  const [companyDetails, setCompanyDetails] = useState(getVariable("companyDetails"));
  if (userProfile) {
    if (companyDetails && companyDetails.id > 0) {
      return (
        <div className="AppWrapper" >
          <Layout companyDetails={companyDetails}/>
        </div>
      );
    } else return <CompanySelection setCompanyDetails={setCompanyDetails} yearId={userProfile[0] && userProfile[0].id} />;
  } else {
    return <Login setUserProfile={setUserProfile} />;
  }
}

export default App;
