import { Nav } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Avatar from "../../../componants/Avatar/Avatar";
import {dbFormatDate} from "../../../Helpers/General"
import {getVariable, saveVariable} from "../../../Helpers/Session";
import {getItemList} from "../../../Helpers/MasterDataApi";
import { useEffect,useState } from "react";
import Loader from "../../../componants/Loader/Loader";

const Header = (props) => {
  const [isLoading,setIsLoading] = useState(true);
  const curdate = dbFormatDate(false,true);
  
  useEffect(() => {
    
    const setItemList = async () => {
      var itemList = getVariable("itemList",true);
      var paymentList = getVariable("paymentList",true);
      if(!(itemList && itemList.length > 0))
      {
        let result =  await getItemList();
        if(result.itemList && result.itemList.length) saveVariable("itemList",result.itemList,true);
        if(result.paymentList && result.paymentList.length) saveVariable("paymentList",result.paymentList,true);
        setIsLoading(false);
      }
      else setIsLoading(false);
    }
    if(isLoading)
    {
      setItemList();
      
    }
    setIsLoading(false);
  },[isLoading,props])
  if(isLoading)
  {
    return <Loader />
  }
  else return (
    <header className="p-0 header-nav d-flex align-items-center h-5 flex-column">
      <div className="container-fluid p-0 header-block">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="company-logo">
            <a href="/" className="text-dark text-decoration-none">
              <img src="asset/images/company-logo.png" alt="" width="" />
            </a>
          </div>
          <div className="company-dropdown">
            <ul>
              <li className="company-name">
                <Nav>
                  <Nav.Link href="/">
                    {props.companyDetails.company_name + "(" + props.companyDetails.financial_year_begin + " To " +props.companyDetails.financial_year_end + ")"}
                  </Nav.Link>
                </Nav>
              </li>
            </ul>
          </div>
          <div className="select-date">
            <Form.Group >
              <Form.Label>Sales Date :</Form.Label>
              <Form.Control type="datetime-local" id="invDate" defaultValue={curdate} min={props.companyDetails.financial_year_begin+"T00:00"} max={props.companyDetails.financial_year_end+"T23:59"}/>
            </Form.Group>
          </div>
          <div className="header-right ms-auto d-flex align-items-center">
            <div className="dropdown text-end user-dropdown">
              <button
                className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Avatar
                  fullName="Full Name"
                  pictureUrl=""
                  size="25px"
                  fontSize="16px"
                ></Avatar>
                <i className="mdi mdi-chevron-down"></i>
              </button>
              <ul
                className="dropdown-menu text-small"
                aria-labelledby="dropdownUser1"
              >
                <li>
                  <a
                    className="dropdown-item"
                    onClick={props.logoutAction}
                    href="/"
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
