import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import SelectBox from "../../../componants/SelectBox/SelectBox";
import { getVariable, saveVariable } from "../../../Helpers/Session";

const CompanySelection = (props) => {
  var userProfile = getVariable("user");
  const [yearList, setYearList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [yearId, setYearId] = useState(props.yearId);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      let years = [];
      let cmpList = [];
      userProfile.forEach((row) => {
        years.push({ id: row.id, label: row.year });
        if (yearId === row.id) {
          row.company.forEach((cmp) => {
            cmpList.push(cmp);
          });
        }
      });
      setYearList(years);
      setCompanyList(cmpList);
      setIsLoading(false);
    }
  }, [isLoading, yearId, yearList, companyList, userProfile]);
  const selectCompany = (event) => {
    const data = JSON.parse(event.target.getAttribute("data-values"));
    data["year_id"] = yearId;
    saveVariable("companyDetails", data);
    props.setCompanyDetails(data);
  };
  const handleYearSelect = (selectedOptions) => {
    let cmpList = [];
    if (selectedOptions && selectedOptions[0]) setYearId(selectedOptions[0].id);
    else setYearId(0);
    setCompanyList(cmpList);
    setIsLoading(true);
  };
  return (
    <div className="page">
      <div className="login-main-page">
        <div className="login-rgt-panel">
          <div className="login-container">
            <div className="login-top">
              <div className="logo">
                {" "}
                <img src="./asset/images/company-logo.png" alt="" width="" />{" "}
              </div>
            </div>
            <div className="login-middle select-middle">
              <div className="login-middle-lft">
                <div className="center_text">
                  <div>
                    You take the <b>Credit</b>,
                  </div>
                  <div>
                    We process the <b>Debit !</b>
                  </div>
                </div>
              </div>
              <div className="login-middle-rgt company-select">
                <div className="select-yr">
                  <SelectBox
                    name="yearId"
                    labelKey="label"
                    valueKey="id"
                    options={yearList}
                    defaultSelected={
                      yearList &&
                      yearList.filter(function (row) {
                        if (yearId > 0) return yearId === row.id;
                        else return [];
                      })
                    }
                    defaultValue={yearId}
                    OnChange={handleYearSelect}
                    filterBy={(option, props) => {
                      if (props.selected.length) {
                        // Display all the options if there's a selection.
                        return true;
                      }
                      // Otherwise filter on some criteria.
                      if (option && option.vTitle) return option.vTitle.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
                      else return true;
                    }}
                  />
                </div>
                <Nav>
                  {companyList.map((row,key) => {
                    return (
                      <Nav.Link
                      key={key}
                        onClick={selectCompany}
                        title={row.company_name + " (" + row.financial_year_begin + ")"}
                        data-id={row.id}
                        data-values={JSON.stringify(row)}
                        href="/"
                      >
                        {row.company_name}
                      </Nav.Link>
                    );
                  })}
                </Nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanySelection;
