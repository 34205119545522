import React from "react";
import { Table } from "react-bootstrap";
const Cart = (props) => {
  const cartItems = props.cartItems;
  const colWidth = {
    name:     "40%",
    qty:      "10%",
    price:    "10%",
     disc:     "10%",
    tax:      "10%",
    amt:      "15%",
    action:   "5%",
  };
  return (
    <div className="sales-block">
      <div className="sales-data custom-table-with-thead">
        <Table responsive bordered hover table-fixed="true">
          <thead>
            <tr>
              <th width={colWidth.name} className="text-left">
                Item Name
              </th>
              <th width={colWidth.qty} className="text-center">
                Quantity
              </th>
              <th width={colWidth.price} className="text-center">
                Price
              </th>

              <th width={colWidth.disc} className="text-center">
                Discount
              </th>
              <th width={colWidth.tax} className="text-center">
                Tax
              </th>
              <th width={colWidth.amt} className="text-right">
                Amount
              </th>
              <th width={colWidth.action} className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item,key) => {
              return(
              <tr key={key} >
                <td width={colWidth.name} className="text-left">
                  {item.name}
                </td>
                <td width={colWidth.qty} className="text-center">
                  {item.cartQty} ({item.unitMasterName})
                </td>
                <td width={colWidth.price} className="text-center">
                  {item.priceVal}
                </td>
                <td width={colWidth.disc} className="text-center">
                  {item.cartDiscountAmount}
                </td>
                <td width={colWidth.tax} className="text-center">
                  {item.cartTaxAmount}
                </td>
                <td width={colWidth.amt} className="text-right">
                  {item.cartNetAmount}
                </td>
                <td width={colWidth.action}  className="text-center">
                  <button onClick={props.removeCartItem} data-id={item.tmpKey} className="btn btn-delete p-0 m-0">
                    <i className="mdi mdi-delete" data-id={item.tmpKey}></i>
                  </button>
                  <button onClick={props.onEditItem} data-id={item.tmpKey} className="btn btn-delete p-0 m-0">
                    <i className="mdi mdi-edit" data-id={item.tmpKey}>edit</i>
                  </button>
                </td>
              </tr>
            )})}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Cart;
