import { Table, InputGroup, Container, Row, Col, ButtonGroup, FormControl } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Form from "react-bootstrap/Form";
import SelectBox from "../../../componants/SelectBox/SelectBox";
import { getVariable } from "../../../Helpers/Session";
import { parseFloat2Digits, apiRequest, printHtml } from "../../../Helpers/General";
import ReactToast from "../../../componants/Toast/ReactToast";
import Cart from "./Cart";
import "./Home.css";
let count = 0;
const Home = () => {
  /* const dicTypeArr = [
    { id: "percentage", label: "Percentage" },
    { id: "amount", label: "Amount" },
  ]; */
  const paymentType = [
    { id: "cash", label: "Cash" },
    { id: "upi", label: "UPI" },
    { id: "debitCard", label: "Debit Card" },
    { id: "creditCard", label: "Credit Card" },
    { id: "cheque", label: "Bank Cheque" },
    { id: "bankTransfer", label: "Bank Transfer" },
  ];
  const [itemList, setItemList] = useState(getVariable("itemList", true));
  const [paymentList, setPaymentList] = useState(getVariable("paymentList", true));
  let [cartItems, setCartItems] = useState([]);
  let [cartRevItems, setRevCartItems] = useState([]);
  let [cartItemId, setCartItemId] = useState(1);
  let [qty, setQty] = useState(1);
  let [price, setPrice] = useState(0);
  let [disc, setDisc] = useState(0);
  const [invoiceInProcess, setInvoiceInProcess] = useState(false);
  const [netAmtChange, setNetAmtChange] = useState(false);
  const [invBtnClass, setInvBtnClass] = useState("empty");
  const [paymentVia, setPaymentVia] = useState("cash");
  const [paymentViaId, setPaymentViaId] = useState();
  const [paymentTrnNo, setPaymentTrnNo] = useState("");
  const [showHideTrnNo, setShowHideTrnNo] = useState("hidden");
  const [discountType, setDiscountType] = useState("amount");
  const [discount, setDiscount] = useState(null);
  const [discountAmt, setDiscountAmt] = useState(null);
  const [netAmount, setNetAmount] = useState(0);
  const [roundOff, setRoundOff] = useState("");
  let [foundItem, setFoundItem] = useState(null);
  const [foundItemText, setFoundItemText] = useState("");
  let [shouldCalculate, setShouldCalculate] = useState(false);
  const [editedItem, setEditedItem] = useState(false);
  const [barCodeScan, setBarCodeScan] = useState(false);
  /* 
  const[searchType,setSearchType] = useState("item_barcode");
  */

  var default_search_radio =  localStorage.getItem("default_search_radio");
  const[searchType,setSearchType] = useState( default_search_radio || "item_code");  

  const[searchTypeDisable,setSearchTypeDisable] = useState(false);
  
  const defaultSummeryValue = {
    totalQty: 0,
    totalItems: 0,
    gross: 0,
    discount: 0,
    taxable: 0,
    tax: 0,
    net: 0,
    cgst: 0,
    sgst: 0,
    cess: 0,
    roundOff: 0,
    finalNet: 0,
  };
  let qtyInput, searchInput, priceInput, discountInput, barCodeInput;
  const [summery, setSummery] = useState(defaultSummeryValue);

  const clearCartItems = async () => {
    makeSearchEmpty("");
    foundItem = null;
    setFoundItem(foundItem);
    setQty(1);
    cartItems = [];
    setCartItems(cartItems);
    setRevCartItems(cartItems);
    doSummeryCalculate();
  };

  const makeSearchEmpty = (text = "") => {
    setFoundItem(null);
    setFoundItemText(text);
    let searchBox = document.getElementById("searchItem");
    if (searchBox) searchBox.value = "";
    let itmQtyBox = document.getElementById("itmQty");
    if (itmQtyBox) itmQtyBox.value = 1;
    let selectCode = document.getElementById("searchItemCode");
    if (selectCode) selectCode.value = "";
    //document.getElementById("itmDiscount").value = 0;
    setDiscount(0);
    setDiscountAmt(0);
    setNetAmount(0);
  };
  
  const findItem = (text,event) => {
    //this function is used to auto select first value if pasted.
    text *= 1;
    if(event.nativeEvent.inputType === "insertFromPaste" && text !== "" && isNaN(text) !== true && typeof text === "number")
    {
    
    if (text.toString().length > 1 && itemList) {
      let item = itemList.filter((row) => row.vBarCode && parseInt(row.vBarCode) === parseInt(text));
      if (item && item.length > 0) {
        item = item[0];
        setFoundItem(item);
        let showText = "";
        showText += item.name;
        if (item.vBarCode) showText += "-" + item.vBarCode;
        showText += " (Price: " + item.fSaleRate + ")";
        setShouldCalculate(true);
        setBarCodeScan(true);
        // setFoundItemText(showText);
      } else {
        makeSearchEmpty("Not Found");
      }
    } else if (text.length === 0) {
      makeSearchEmpty("");
    }
  }
  };

  const handleItemSelect = (selectedOptions) => {
    setNetAmtChange(false);
    //debugger;
    if (selectedOptions && selectedOptions[0]) {
      setFoundItem(selectedOptions[0]);
      //qtyInput.focus();
      searchInput.setItem(selectedOptions[0]);
      //searchInput.focus();
      qtyInput.focus();
      setShouldCalculate(true);
    } else {
      makeSearchEmpty("Not Found");
    }
  };

  const handleItemCodeSelect = (selectItem) => {
    setNetAmtChange(false);
    if (selectItem && selectItem[0]) {
      setFoundItem(selectItem[0]);
      searchInput.setItem(selectItem[0]);
      qtyInput.focus();
      setShouldCalculate(true);
    } else {
      makeSearchEmpty("Not Found");
    }
  }

  const doSummeryCalculate = () => {
    let tmpSummery = defaultSummeryValue;
    cartItems.forEach((row) => {

      tmpSummery.totalItems += 1;
      tmpSummery.totalQty = parseFloat2Digits(parseFloat(tmpSummery.totalQty) + parseFloat(row.cartQty));
      tmpSummery.gross = parseFloat2Digits(tmpSummery.gross + row.cartGrossAmt);
      tmpSummery.discount = parseFloat2Digits(tmpSummery.discount + row.cartDiscountAmount);
      tmpSummery.taxable = parseFloat2Digits(tmpSummery.taxable + row.cartTaxableAmount);
      tmpSummery.cgst = parseFloat2Digits(tmpSummery.cgst + row.cartCGST);
      tmpSummery.sgst = parseFloat2Digits(tmpSummery.sgst + row.cartSGST);
      tmpSummery.cess = parseFloat2Digits(tmpSummery.cess + row.cartCess);
      tmpSummery.tax = parseFloat2Digits(tmpSummery.tax + row.cartTaxAmount);
      tmpSummery.net += parseFloat2Digits(row.cartNetAmount);
      tmpSummery.finalNet += parseFloat2Digits(row.cartNetAmount);
    });
    summery.roundOff = parseFloat2Digits(tmpSummery.net - Math.round(tmpSummery.net)) * -1;
    //summery.roundOff = parseFloat2Digits(tmpSummery.net - Math.round(tmpSummery.net));
    //if(Math.round(tmpSummery.net) >  tmpSummery.net) {
    //}
    tmpSummery.finalNet = parseFloat2Digits(tmpSummery.net + summery.roundOff);
    
    setRoundOff(summery.roundOff);
    setSummery(tmpSummery);
  };
  const roundOffChange = (event) => {
    setRoundOff(event.target.value);
    summery.roundOff = parseFloat2Digits(event.target.value);
    summery.finalNet = parseFloat2Digits(summery.net + summery.roundOff);
    setSummery(summery);
  };
  const addToCart = (text) => {
    setSearchTypeDisable(false);
    setEditedItem(false);
    setBarCodeScan(false);
    if (!foundItem || foundItem.length < 0) {
      return;
    }
    if (parseFloat(qty) <= 0) {
      ReactToast({ type: "error", message: "Quantity Missing." });
      return;
    }
    if (foundItem && (foundItem["fSaleRate"] === "" || foundItem["fSaleRate"] <= 0)) {
      ReactToast({
        type: "error",
        message: "Price is not available for this item.",
      });
      return;
    }

    foundItem["tmpKey"] = cartItemId;
    cartItems = cartItems.filter((item, index) => {
      if ((parseInt(item.pkid) === parseInt(foundItem.pkid))) {
        const cartQty = parseFloat(cartItems[index].cartQty);
        foundItem.cartQty = cartQty + parseFloat(text==="barCode" ? 1 : qty);
        setFoundItem(cartItems);
        shouldCalculate = true;
        return false;
      } else return true;
    });
    cartItems.push(foundItem);
    setCartItems(cartItems);
    // setRevCartItems(cartItems.reverse()); // commented by sr to set order
    setRevCartItems(cartItems);
    doCalculate();
    makeSearchEmpty("");
    doSummeryCalculate();
    setCartItemId(cartItemId + 1);
    setPrice(0)
    setDisc(0)
    if (!editedItem)
      searchInput.clear();
  };

  const onEditItem = (event) => {
    if (!editedItem) {
      setEditedItem(true);
      let tmpKey = event.target.getAttribute("data-id");
      const editItem = cartItems.filter((element) => parseInt(element.tmpKey) === parseInt(tmpKey));
      setSearchTypeDisable(true);
      editItem.map((item,key) => {
        if(item.itemTitleOrg == undefined || item.itemTitleOrg == '') {
          item.itemTitleOrg = item.itemTitle;
          item.vUniqueCodeOrg = item.vUniqueCode;
        }
        if(searchType == 'item_code') {
          item.vUniqueCode = item.vUniqueCodeOrg ? item.itemTitleOrg+' ('+item.vUniqueCodeOrg+')' :  item.vUniqueCodeOrg;
        } else {
          item.itemTitle = item.vBarCode ? item.itemTitleOrg+' ('+item.vBarCode+')' :  item.itemTitleOrg;
        }
      });
      setDiscountType(editItem[0].cartDiscountType)
      setFoundItem(editItem)
      handleItemSelect(editItem);
      const remainingItems = cartItems.filter((element) => parseInt(element.tmpKey) !== parseInt(tmpKey));
      if (remainingItems.length > 0) {
        setCartItems(remainingItems);
        // setRevCartItems(remainingItems.reverse()); // commented by sr to set order
        setRevCartItems(remainingItems);

      }
      else {
        setCartItems([]);
        // setRevCartItems(remainingItems.reverse()); // commented by sr to set order
        setRevCartItems(remainingItems);
      }
    } else {
      ReactToast({
        type: "error",
        message: "not allowed multiple edit"

      })
    }
  }
  const removeCartItem = (event) => {
    let tmpKey = event.target.getAttribute("data-id");
    const remainingItems = cartItems.filter((element) => parseInt(element.tmpKey) !== parseInt(tmpKey));
    if (remainingItems.length > 0) {
      setCartItems(remainingItems);
      // setRevCartItems(remainingItems.reverse());  // commented by sr to set order
      setRevCartItems(remainingItems);
      doSummeryCalculate();
    }
    else {
      clearCartItems();
    }
  };
  const doCalculate = async () => {
    count += 1;
    if (!shouldCalculate) return;
    const itmQtyBox = document.getElementById("itmQty");
    if (itmQtyBox) qty = itmQtyBox.value;
    else qty = 0;
    if (foundItem && (foundItem.fSaleRate) > 0 && qty > 0) {
      let cgst = 0,
        sgst = 0,
        cess = 0,
        taxAmount = 0;
      var tQty = foundItem.cartQty || qty;
      var amt = parseFloat2Digits(parseFloat2Digits(foundItem.priceVal || foundItem.fSaleRate) * tQty);
      var discAmt = 0;
      let discountPer = discount;
      if (discount > 0) {
        if (discountType === "percentage") discAmt = parseFloat2Digits(amt * ((discount) / 100));
        else {
          discountPer = (discount / amt) * 100;
          discAmt = parseFloat2Digits(discount);
        }
      }
      const taxable = parseFloat2Digits(amt - discAmt);
      if (parseFloat(foundItem.fCGST) > 0) {
        cgst = parseFloat2Digits(taxable * (parseFloat(foundItem.fCGST) / 100));
        taxAmount += cgst;
      }
      if (parseFloat(foundItem.fSGST) > 0) {
        sgst = parseFloat2Digits(taxable * (parseFloat(foundItem.fSGST) / 100));
        taxAmount += sgst;
      }
      if (foundItem.eCessApply === "Yes" && parseFloat(foundItem.fCessPercentage) > 0) {
        cess = parseFloat2Digits(taxable * (parseFloat(foundItem.fCessPercentage) / 100));
        taxAmount += cess;
      }
      const pricert = foundItem.priceVal || foundItem.fSaleRate;
      const Discrs = foundItem.discVal || discAmt || disc;
      var netAmount = parseFloat2Digits( parseFloat2Digits(taxable )+  parseFloat2Digits(taxAmount) - parseInt(Discrs));
      if (netAmtChange) {        
        netAmount = foundItem.cartNetAmount
        amt = foundItem.cartNetAmount
        tQty = parseFloat2Digits(parseFloat(foundItem.cartNetAmount)/ (parseFloat(foundItem.fSaleRate) + (taxAmount/tQty) ))
        setQty(tQty)
      }
      if (Discrs > 0) {
        if (discountType === "percentage") {
          discAmt = parseFloat2Digits(amt * (parseInt(Discrs) / 100));
          netAmount = (amt + taxAmount )- discAmt
        }
        else {
          discountPer = (parseInt(Discrs) / amt) * 100;
          discAmt = parseFloat2Digits(Discrs);
          netAmount = (amt + taxAmount ) - discAmt
        }
      }
      foundItem["cartQty"] = tQty;
      foundItem["cartGrossAmt"] = parseFloat2Digits(amt);
      foundItem["priceVal"] = pricert;
      foundItem["discVal"] = Discrs;
      foundItem["cartDiscountType"] = discountType;
      foundItem["cartDiscountValue"] = Discrs;
      foundItem["cartDiscountPer"] = discountPer;
      foundItem["cartDiscountAmount"] = discAmt;
      foundItem["cartTaxableAmount"] = taxable;
      foundItem["cartCGST"] = cgst;
      foundItem["cartSGST"] = sgst;
      foundItem["cartCess"] = cess;
      foundItem["cartTaxAmount"] = taxAmount;
      foundItem["cartNetAmount"] = parseFloat2Digits(netAmount);
      setFoundItem(foundItem);
      setDiscountAmt(discAmt);
      setNetAmount(parseFloat2Digits(netAmount));
      setPrice(pricert)
      setDisc(Discrs)
      shouldCalculate = false;
      if (barCodeScan) {
        addToCart("barCode");
      }
    }
    if(count === 2){
     setShouldCalculate(false);
     count = 0;
    }
  };
  const printInvoice = async (invoiceId) => {
    const apiSetting = {
      method: "POST",
      apiParams: {
        iInvoiceMasterId: invoiceId,
      },
    };
    const apiResult = await apiRequest("printPosSalesInvoice", apiSetting);

    if (apiResult) {
      if (apiResult.data.printHtml && apiResult.data.printHtml !== '') {
        printHtml(decodeURIComponent(escape(atob(apiResult.data.printHtml))));
      } else {
        //throw new Error(apiResult.data.message);
      }
    } else {
      throw new Error("Something went wrong!!");
    }
  }
  const creatInvoice = async (e) => {
    setInvoiceInProcess(true);
    setInvBtnClass("inProcess");
    try {
      let invDate = document.getElementById("invDate").value;

      const apiSetting = {
        method: "POST",
        apiParams: {
          dInvDt: invDate,
          iPaymentModeId: paymentViaId,
          paymentVia: paymentVia,
          paymentTrnNo: paymentTrnNo,
          cartItems: cartItems,
          roundOff: roundOff,
        },
      };
      const apiResult = await apiRequest("addPosSalesInvoice", apiSetting);
      if (apiResult && apiResult.data) {
        if (apiResult.data.success === 1) {
          clearCartItems();
          ReactToast({ type: "success", message: "Invoice No: " + apiResult.data.invno + ". " + apiResult.data.message });
          await printInvoice(apiResult.data.id);
        } else {
          throw new Error(apiResult.data.message);
        }
      } else {
        throw new Error("Something went wrong!!");
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
      return [];
    } finally {
      setInvoiceInProcess(false);
    }
  };

  const handleGetItems = () => {
    try {
      const items = getVariable("itemList", true);
      const paymentOption = getVariable("paymentList", true);
      setItemList(items);
      setPaymentList(paymentOption);
    } catch (error) {
      console.log("select focust error", error.message);
    }
  };
  useEffect(() => {
    if (shouldCalculate) doCalculate();
    if (invoiceInProcess) setInvBtnClass("inProcess");
    else if (cartItems.length > 0) setInvBtnClass("cartFill");
    else setInvBtnClass("empty");
    /* const button = document.getElementById("createInv");
    if (button) {
      if (cartItems.length > 0 && invoiceInProcess === false) button.disabled = false;
      else button.disabled = true;
    } */
  }, [foundItem, shouldCalculate, invoiceInProcess, invBtnClass, cartItems, doCalculate,searchType]);
  const addToCartButton = <button onClick={addToCart} className="add-to-cart btn btn-primary mdi-cart-arrow-right mdi mdi-24px" />;
  const ProcessIcon = () => {
    return <div className="loader-container">{/* <img src="/asset/images/invoice_receipt_animation.gif" alt="" width="300px"/> */}</div>;
  };

  return (
    <div className="page-wrapper" id="Home">
      <div className="page-content text-center">
        <div className="middle-container">
          <div className="middle-content">
            <div className="inner-midl-content">
              <div className="content-area">
                <div className="search-area">
                  <Container fluid className="p-0">
                    {invoiceInProcess && <ProcessIcon />}
                    <Row>
                      <Col xs md={2} xs={'search_radio_block_wrap'}>
                        <div className="form-row">
                          <div className="form-input-div search-radio-block">
                              <label className="float-start">
                                Search By
                              </label>
                              <div className="d-flex">
                                <input type="radio" 
                                  checked={searchType === "item_barcode" ? true : false} 
                                  value="item_barcode" 
                                  name="item_code" 
                                  id="item_barcode"
                                  onClick={()=>{setSearchType("item_barcode")}}
                                  onChange={(event) => {}}
                                  disabled={searchTypeDisable}
                                  /> 
                                <label className="ml-2 lbl_item_barcode" htmlFor="item_barcode"> Item / Barcode</label>
                                <input type="radio" 
                                  checked={searchType === "item_code" ? true : false}
                                  value="item_code" 
                                  name="item_code" 
                                  id="item_code"
                                  onClick={()=>{setSearchType("item_code")}}
                                  onChange={(event) => {}}
                                  disabled={searchTypeDisable}
                                /> <label className="ml-2" htmlFor="item_code"> Item Code</label>
                              </div>
                          </div>
                        </div>
                      </Col>
                      {searchType === "item_barcode" ? <Col xs md={4}>
                        {!editedItem ? <Form.Group className="search-input">
                          <InputGroup className="border-right-0 border-left-0">
                            <InputGroup.Append>
                              <InputGroup.Text className="bg-white border-right-0 icon-search">
                                <img src="./asset/images/icon-search.png" alt="" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                            {/* <Form.Control
                              type="textbox"
                              placeholder="Search Barcode or Item Name"
                              ref={(input) => {
                                barCodeInput = input;
                              }}
                              onChange={findItem}
                              id="barcodeItem"
                              onKeyPress={() => qtyInput.focus()}
                            /> */}
                            <SelectBox
                              placeholder="Search By Item Name or Barcode"
                              id="searchItem"
                              name="searchItem"
                              labelKey={(option) => (option.vBarCode ? `${option.itemTitle} (${option.vBarCode})` : option.itemTitle)}
                              valueKey="pkid"
                              minLength={2}
                              options={itemList}
                              defaultSelected={foundItem ? [foundItem] : []}
                              defaultValue={foundItem ? [foundItem] : []}
                              OnChange={handleItemSelect}
                              onFocus={handleGetItems}
                              onInputChange={findItem}
                              arrowIconHide={true}
                              ref={(input) => {
                                searchInput = input;
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text className="bg-white border-left-0">
                                <img src="asset/images/icon-barcode.png" alt="" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group> : <Form.Group className="search-input">
                          <InputGroup className="border-right-0 border-left-0">
                            <InputGroup.Append>
                              <InputGroup.Text className="bg-white border-right-0 icon-search">
                                <img src="./asset/images/icon-search.png" alt="" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              ref={(input) => {
                                searchInput = input;
                              }}
                              defaultValue={foundItem ? foundItem.itemTitle : ""}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text className="bg-white border-left-0">
                                <img src="asset/images/icon-barcode.png" alt="" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>}
                      </Col> :'' }
                      
                      {searchType === "item_code" ? <Col xs md={4}>
                          {!editedItem ? <Form.Group className="search-input">
                            <InputGroup className="border-right-0 border-left-0">
                              <InputGroup.Append>
                                <InputGroup.Text className="bg-white border-right-0 icon-search">
                                  <img src="./asset/images/icon-search.png" alt="" />
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <SelectBox
                                placeholder="Search By Item Code"
                                id="searchItemCode"
                                name="searchItemCode"
                                labelKey={(option) => (option.vUniqueCode ? `${option.itemTitle} (${option.vUniqueCode})` : option.vUniqueCode)}
                                valueKey="vUniqueCode"
                                minLength={1}
                                options={itemList}
                                defaultSelected={foundItem ? [foundItem] : []}
                                defaultValue={foundItem ? [foundItem] : []}
                                OnChange={handleItemCodeSelect}
                                onFocus={handleGetItems}
                                onInputChange={findItem}
                                arrowIconHide={true}
                                ref={(input) => {
                                  searchInput = input;
                                }}
                              />
                            </InputGroup>
                          </Form.Group> : <Form.Group className="search-input">
                            <InputGroup className="border-right-0 border-left-0">
                              <InputGroup.Append>
                                <InputGroup.Text className="bg-white border-right-0 icon-search">
                                  <img src="./asset/images/icon-search.png" alt="" />
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                ref={(input) => {
                                  searchInput = input;
                                }}
                                defaultValue={foundItem ? foundItem.vUniqueCode : ""}
                              />
                            </InputGroup>
                          </Form.Group>}
                      </Col> : ''}
                      <Col xs={2} md={2} xl={2} xs={'qty_input_wrap'}>
                        <Form.Group>
                          <InputGroup className="">
                            <Form.Control
                              id="itmQty"
                              type="number"
                              min="1"
                              step="1"
                              placeholder="Qty"
                              size="sm"
                              ref={(input) => {
                                qtyInput = input;
                              }}
                              defaultValue={(foundItem && foundItem.cartQty) || 1}
                              onChange={(event) => {
                                qty = event.target.value || 0;
                                setShouldCalculate(true);
                                setQty(qty);
                                setNetAmtChange(false);
                                if (foundItem && foundItem.cartQty) foundItem.cartQty = qty;
                              }}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  addToCart();
                                  if (!editedItem) {
                                    searchInput.clear();
                                    searchInput.focus();
                                  }
                                }
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>{(foundItem && foundItem.unitMasterName) ? foundItem.unitMasterName : 'QTY'}</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col xs={2} md={2} xl={2} xs={'price_input_wrap'}>
                        <Form.Group>
                          <InputGroup className="">
                            <Form.Control
                              id="itmPrice"
                              type="number"
                              min="1"
                              step="1"
                              placeholder="Price"
                              size="sm"
                              ref={(input) => {
                                priceInput = input;
                              }}
                              value={price}
                              onChange={(event) => {
                                qty = event.target.value || '';
                                setShouldCalculate(true);
                                setPrice(qty);
                                setNetAmtChange(false);
                                if (foundItem && foundItem.priceVal) foundItem.priceVal = qty;
                              }}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  addToCart();
                                  if (!editedItem) {
                                    searchInput.clear();
                                    searchInput.focus();
                                  }
                                }
                              }}
                              readOnly="readOnly"
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>Price</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col xs={3} md={3} xl={3} xs={'discount_input_wrap'}>
                        <Form.Group className="discount-block-amt">
                          <InputGroup className="">
                            <Form.Control
                              id="itmdiscount"
                              type="number"
                              min="0"
                              step="1"
                              placeholder="Discount"
                              size="sm"
                              ref={(input) => {
                                discountInput = input;
                              }}
                              value={disc}
                              onChange={(event) => {
                                qty = event.target.value || '';
                                setShouldCalculate(true);
                                setDisc(qty);
                                setNetAmtChange(false);
                                if (foundItem && foundItem.discVal) foundItem.discVal = qty;
                              }}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  addToCart();
                                  if (!editedItem) {
                                    searchInput.clear();
                                    searchInput.focus();
                                  }
                                }
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>Discount</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                        <div className="d-flex">
                          <input type="radio" 
                            checked={discountType === "percentage" ? true : false} 
                            value="percentage" 
                            name="discount" 
                            onClick={() => {
                            setShouldCalculate(true);
                            setDiscountType("percentage")}}
                            onChange={(event) => {}}
                            className="d-none"
                            /> 
                          <span className="ml-2 mt-2 mr-2 d-none"> per</span>
                          <input type="radio" 
                            checked={discountType === "amount" ? true : false}
                            value="amount" 
                            name="discount" 
                            onClick={() => {
                              setShouldCalculate(true);
                              setDiscountType("amount")}} 
                            onChange={(event) => {}}
                            className="d-none"
                          /> <span className="ml-2 mt-2  d-none"> Amt</span>
                        </div>
                      </Col>
                      
                      <Col xs={2} md={2} xs={'net_amount_input_wrap'}>
                        <Form.Group className="total-net-amt">
                          <InputGroup className="">
                            <Form.Control
                              id="netAmount"
                              type="text"
                              min="1"
                              step="1"
                              placeholder="Net Amount"
                              size="sm"
                              value={netAmount || 0.0}
                              onChange={(event) =>
                              // netAmountChange(event)}
                              {
                                setShouldCalculate(true);
                                setNetAmount(event.target.value);
                                setNetAmtChange(true);
                                foundItem.cartNetAmount = event.target.value;
                              }}
                              readOnly="readOnly"
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>Net Amount</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col xs={1} md={1} xs={'addtocart_btn_input_wrap'}>
                        <Form.Group>{foundItem && addToCartButton}</Form.Group>
                      </Col>

                    </Row>
                    
                  </Container>
                </div>
                <div className="sales-data-block">
                  <Cart cartItems={cartRevItems} onEditItem={onEditItem} removeCartItem={removeCartItem} />
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar p-3 d-flex flex-column payment_save_block_data">
            <div className="payment-mode mb-2">
              <div className="small-title p-2 text-left">
                <h3>Payment Details</h3>
              </div>
              <div className="p-4 ">
                <Form.Group className="mb-2 ">
                  <SelectBox
                    id="paymentOptId"
                    name="paymentOptId"
                    labelKey="vTitle"
                    valueKey="iId"
                    options={paymentList}
                    defaultSelected={paymentList && paymentList.slice(0, 1)}
                    OnChange={(selectedOptions) => {
                      if (selectedOptions && selectedOptions[0]) {
                        setPaymentVia(selectedOptions[0].vTitle);
                        setPaymentViaId(selectedOptions[0].iId);
                        if (selectedOptions[0].vTitle.toLowerCase() === "cash") setShowHideTrnNo("hidden");
                        else setShowHideTrnNo("");
                      }
                    }}
                    filterBy={(option, props) => {
                      if (props.selected.length) {
                        // Display all the options if there's a selection.
                        return true;
                      }
                      // Otherwise filter on some criteria.
                      if (option && option.vTitle) return option.vTitle.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
                      else return true;
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="textbox"
                    placeholder="Cheque/Transaction Number"
                    onChange={(event) => setPaymentTrnNo(event.target.value)}
                    id="chequeTransactionNumber"
                    hidden={showHideTrnNo}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="payment-mode mb-2">
              <div className="small-title p-2 text-left">
                <h3>Customer Details</h3>
              </div>
              <div className="p-4 ">
              <Form.Group>  
                  <Form.Control
                    type="number"
                    placeholder="Customer Mobile Number"
                    id="customerMobileNo"
                    defaultValue=""
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength="10"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0,e.target.maxLength);
                   }}
                  />
                </Form.Group>   
                  <br />
                  <Form.Control
                    type="textbox"
                    placeholder="Customer Name"
                    id="customerName"
                    defaultValue=""
                  />
               
              </div>
              
            </div>

            <div className="invoice-summary mt-auto p-0">
              <Table responsive bordered table-fixed="true">
                <tbody>
                  {/*   {summery.gross > 0 && ( */}
                  <>
                    <tr>
                      <th width="45%" className="text-left">
                        <div>
                          Total Items / Quantity
                          <i variant="primary" className="d-none">
                            <img src="asset/images/icon-note.png" alt="" />
                          </i>
                        </div>
                      </th>
                      <td width="55%" className="text-right">
                        <div>{summery.totalItems} / {summery.totalQty}</div>
                      </td>
                    </tr>
                    <tr>
                      <th width="45%" className="text-left">
                        <div>
                          Gross Amount{" "}
                          <i variant="primary" className="d-none">
                            <img src="asset/images/icon-note.png" alt="" />
                          </i>
                        </div>
                      </th>
                      <td width="55%" className="text-right">
                        <div>{summery.gross}</div>
                      </td>
                    </tr>
                    <tr>
                      <th width="45%" className="text-left">
                        <div>
                          Discount Amount{" "}
                          <i variant="primary" className="d-none">
                            <img src="asset/images/icon-note.png" alt="" />
                          </i>
                        </div>
                      </th>
                      <td width="55%" className="text-right">
                        <div>{summery.discount}</div>
                      </td>
                    </tr>
                    <tr>
                      <th width="45%" className="text-left">
                        <div>
                          Tax Amount{" "}
                          <small className="d-flex flex-column">
                            <label className="m-0 w-100">
                              CGST: <em className="float-right">{summery.cgst}</em>
                            </label>
                            <label className="m-0 w-100">
                              SGST: <em className="float-right">{summery.sgst}</em>
                            </label>
                            <label className="m-0">
                              Cess: <em className="float-right">{summery.cess}</em>
                            </label>
                          </small>
                          <i variant="primary" className="d-none">
                            <img src="asset/images/icon-note.png" alt="" />
                          </i>
                        </div>
                      </th>
                      <td width="55%" className="text-right">
                        <div>{summery.tax}</div>
                      </td>
                    </tr>
                    <tr>
                      <th width="45%" className="text-left">
                        <div>
                          <b>Round Off</b>
                        </div>
                      </th>
                      <td width="55%" className="text-right">
                        <div>
                          <b>{roundOff}</b>
                        </div>
                      </td>
                    </tr>
                    <tr className="ttl-row">
                      <th width="45%" className="text-left">
                        <div>
                          <b>Net Amount</b>{" "}
                          <i variant="primary" className="d-none">
                            <img src="asset/images/icon-note.png" alt="" />
                          </i>
                        </div>
                      </th>
                      <td width="55%" className="text-right">
                        <div>
                          <b>{summery.finalNet}.00</b>
                        </div>
                      </td>
                    </tr>
                  </>
                  {/*  )} */}
                </tbody>
              </Table>
            </div>
            <button onClick={creatInvoice} id="createInv" type="button" className={invBtnClass + " btn btn-lg btn-primary create-btn"}>
              Save & Print {invoiceInProcess && <ReactLoading className="react-loading" type="cylon" color="#ffffff" height={"20%"} width={"20%"} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
