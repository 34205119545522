/* import {Route} from 'react-router-dom'; */
import {Route} from 'react-router';
import MenuRoutes from './MenuRoutes';
import CompanySelection from '../../LoginNotRequired/Auth/CompanySelection'
const Routing = () => ( 
    <div className="routing">
        {MenuRoutes.map((row,index) => {
          return <Route key={index} exact={row.exact} path={row.path} component={row.component} />
        })}
        <Route exact path="/CompanySelection" component={CompanySelection} />
    </div>
);
export default Routing;