import axios from 'axios';
import {clearAll,saveVariable,getVariable} from './Session';
//capitalize only the first letter of the string. 
export const ucFirst = (string) => (string.charAt(0).toUpperCase() + string.slice(1));
export const parseFloat2Digits = (number) => number !== "" ? Math.round(number * 100) / 100 : 0;
export const dbFormatDate = (date,isTrequierd = false) => {
  var d = new Date();
  if(date) d = new Date(date) ;
  var month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear(),
  hour = '' + d.getHours(),
  minute = '' + d.getMinutes(),
  second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day].join('-') + (isTrequierd ? 'T' : ' ') +[hour, minute, second].join(':') ;
}
export const apiRequest = async (apiFunc, apiSetting ={}) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;multipart/form-data';
  axios.defaults.headers.get['Content-Type'] = 'application/json;charset=UTF-8';
  axios.defaults.timeout = 1000 * 60; // Wait for 60 seconds
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const method = apiSetting.method ? apiSetting.method : "GET";
  const apiParams = apiSetting.apiParams ? apiSetting.apiParams : {};
  const fileInput = apiSetting.fileInput ? apiSetting.fileInput : null;
  apiParams['lang_id'] = apiParams.lang_id || "EN";
  apiParams['ws_debug'] = "1";
  const cmpInfo = getVariable("companyDetails");
  if(cmpInfo && cmpInfo.id)
  {
    apiParams['company_id'] = cmpInfo.id;
    apiParams['year_id'] = cmpInfo.year_id;
  }
  const headerObj = {"pos" : 1,api_type: "mobile"};
  const axiosConfig = {cancelToken: source.token};  
  const tokenObj = getVariable("token");
  if(tokenObj && tokenObj.token) headerObj['token'] =tokenObj.token;

  if(apiSetting.headers && apiSetting.headers.lenght > 0)
  {
    
    for(const [hk,hv] of Object.entries(apiSetting.headers))
    {
      headerObj[hk] = hv;
    }
  }
  if(Object.keys(headerObj).length > 0)
  {
    axiosConfig['headers'] = headerObj;
  }
  
  var apiResult = null;
  if(method === "POST")
  {
    let formData = new FormData();    
    for(const [key,value] of Object.entries(apiParams))
    {
      if(typeof value === "object")
        formData.append(key, JSON.stringify(value))
      else
        formData.append(key, value && value);
    }
    //loop on file input keys
    if(fileInput)
    {
      for(const [key,fileObjects] of Object.entries(fileInput))
      {
        //formData.append(`${key}`, fileObjects)
        for(const [fKey,fileObj] of Object.entries(fileObjects))
        {
          formData.append(`${key}[${fKey}]`, fileObj)
        }
      }
    }
    //loop on file input key's array
    apiResult = await axios.post(apiFunc,formData,axiosConfig);
  }
  else
  {
    axiosConfig['params'] = apiParams;
    apiResult = await axios.get(apiFunc,axiosConfig);
  }
  if(apiResult && apiResult.data && (apiResult.data.error  === "401" || apiResult.data.success === "401")) {clearAll();window.location.reload();}
  if(apiResult && apiResult.headers && apiResult.headers.token)
  {
    saveVariable("token", {"token" :apiResult.headers.token});
  }
  return apiResult;
}

export const printHtml = (htmlCode) => {
  console.log(htmlCode);
  let mywindow = window.open('', 'PRINT');
  mywindow.document.write(htmlCode);
  setTimeout(() => {
    mywindow.print();
  mywindow.close();
  }, 100);
  

  return true;
}
