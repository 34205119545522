import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { saveVariable } from "../../../Helpers/Session";
import ReactToast from "../../../componants/Toast/ReactToast";
import "./Auth.css";

export default function Login(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("mdi mdi-eye-off");
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEyeClass(passwordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger");
  };

  const loginAction = async (data) => {
    setIsApiCalling(true);
    try {
      const rememberMe = data.rememberMe;
      const url = process.env.REACT_APP_API_AUTH;
      let formData = new FormData();
      formData.append("vEmail", data.email);
      formData.append("vPassword", data.password);
      const apiResult = await axios.post(url, formData, {
        headers: { api_type: "mobile", pos_login: "yes" },
      });
      if (apiResult.data.success === "1") {
        let user = apiResult.data.data;
        if (rememberMe) {
          saveVariable("rememberMe", rememberMe, true);
          saveVariable("user", user, true);
        } else {
          saveVariable("user", user);
        }
        saveVariable("token", {"token" :apiResult.headers.token});
        props.setUserProfile(user);
      }
      else if(apiResult && apiResult.data.message) 
        throw new Error(apiResult.data.message);
      else
        throw new Error("Something Went Wrong! Please try later");
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
    } finally {
      setIsApiCalling(false);
    }
  };

  return (
    <div className="page">
      <div className="login-main-page">
        <div className="login-rgt-panel">
          <div className="login-container">
            <div className="login-top">
              <div className="logo">
                <img src="./asset/images/company-logo.png" alt="" width="" />
              </div>
            </div>
            <div className="login-middle">
              <div className="login-middle-lft">
                <div className="center_text">
                  <div>
                    You take the <b>Credit</b>,
                  </div>
                  <div>
                    We process the <b>Debit !</b>
                  </div>
                </div>
              </div>
              <div className="login-middle-rgt login-form m-auto d-flex align-items-center flex-column">
                <div className="welcome_text">
                  Welcome to <span>Accountant Desk !</span>
                </div>
                <form className="mt-2" onSubmit={handleSubmit(loginAction)}>
                  <div className="form-row mb-3 mt-3 email-row">
                    <label htmlFor="inputEmail" className="float-start mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="inputEmail"
                      className="form-control"
                      placeholder="Email address"
                      autoFocus
                      {...register("email", {
                        required: "Enter your e-mail",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Enter a valid e-mail address",
                        },
                      })}
                    />
                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.email?.message}
                    </div>
                  </div>
                  <div className="form-row mb-3 forgotpass-row">
                    <label htmlFor="inputPassword" className="float-start mb-1">
                      Password
                    </label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="inputPassword"
                      className="form-control"
                      placeholder="Password"
                      {...register("password", {
                        required: "Enter your password",
                        minLength: {
                          value: 6,
                          message: "Minimum 6 characters",
                        },
                      })}
                    />
                    <i
                      onClick={togglePasswordVisiblity}
                      className={eyeClass + " mdi password-eye"}
                    ></i>
                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.password?.message}
                    </div>
                  </div>

                  {/* <div className="form-row form-action d-flex w-100 justify-content-center">
                    <div className="forgot-link">
                      <a href="/" className="ms-auto">
                        forgot password
                      </a>
                    </div>
                  </div> */}
                  <div className="action-link">
                    <button
                      className="w-20 btn btn-lg btn-primary signin-btn ms-auto"
                      type="submit"
                      disabled={isApiCalling}
                    >
                      Sign in
                    </button>
                    {isApiCalling && (
                      <i className="mdi mdi-36px mdi-spin mdi-loading"></i>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
